.home_container{
    margin-top: 180px;
    margin-bottom: 210px;
    /* width: 40%; */
}

.slider_img{
    /* width: 200px; */
    max-height: 600px;
}
.carousel_container{
    width: 100%;
    /* align-self: center; */
}

.home_container__carousel{
    display: flex;
    /* max-width: 1000px; */
    width: 100%;
    /* height: 300px; */
}
/* , .axis-horizontal */
.slider-wrapper, .axis-horizontal{
    max-height: 600px !important;
    height: auto;
}
.home_products, .home_products_first{
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    /* margin-bottom: 150px; */
}

.background{
    /* position: absolute; */
    transition: opacity 0.3s;
    /* -webkit-transition: opacity 0.5s; */
    opacity: 1;
    /* margin: 20px; */
}

.home_product img,.home_products2 img, .prueba, .background{
    width: 100%;
    max-width: 470px;
    height: auto;
    /* transition: content 1s; */

}
.home_products_first{
    position:absolute;
}
/* .one img{
    background-image: url("https://raw.githubusercontent.com/los-leones/images/master/home/best/best_00.png");
} */





.background:hover{
    opacity: 0;
}
