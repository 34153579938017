.products{
    margin-top: 150px; /*MARGEN CON EL HEADER*/
    margin-bottom: 10px;
    /* padding-bottom: 40px; */
}
.products__menu{
    margin-left: 50px;
    display: flex;
    flex-direction: column;
    width: 180px;
    position: absolute;
}
input{
    margin-right: 40px;
}
.product_container{
    margin-bottom: 10px;
    margin-right: 30px;
    position:relative;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
}

.products_content_container{
    display: flex;
}

.products__menu-item{
    font-size: 18px;
    letter-spacing: 1px;
    /* text-transform: uppercase; */
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    background: #ffffff;
    padding: 8px 20px;
    margin-bottom: 10px;
    /* border-style: solid; */
    border-width: 1px;
    border-radius: 0px;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    transition: background-color 0.3s;
    /* background-color: var(--primary-color); */
}
.products__menu-item:hover{
    background-color: var(--secondary-color);
}

p{
    font-size: 18px;
    color: #999;
}

.products-title{
    padding: 5px;
    margin-bottom: 20px;
    margin-left: 20px;
    /* width: 100%; */
}

.products__images{
    /* width: 30%; */
    max-width: 100%;
    height: auto;
    margin-top: 15px;
    margin-right: 15px;
    margin-bottom: 35%;
    vertical-align: middle;
    border-style: none;
}
.products__images p{
    display: flex;
    justify-content: center;
}

.products__images{
    /* margin-left: 40px; */
    display: flex;
    flex-flow: row wrap;
    /* justify-content: flex-start; */
    justify-content: space-around;
    margin-left: 400px;
    padding: 5px;
    position: relative;
    overflow: hidden;
    min-width: 100px;
    /* max-width: 350px; */
    height: auto;
    /* height: 1200px; */
    /* max-width: 70%; */
}

.dimension{
    /* z-index:9; */
    opacity: 0;
    transition: opacity 0.5s;
    position: relative;
    width: 100%;
    bottom: 42px;
    display: flex;
    justify-content: space-around;
    flex-wrap: nowrap;
    flex-direction: column;
    background-color: var(--secondary-color);
    /* transition: visibility 1s; */
    /* bottom: 64px;
    left: 15%; */
}
.product_container_img:hover .dimension{
    opacity: 1;    
}

.product_name{
    width: 100%;
    max-width: 226px;
    text-align: center;
    /* opacity: 0; */
}



.product_container img{
    transition: box-shadow 0.5s;
}
.product_container img:hover{
    cursor: pointer;
    box-shadow: 0 0 10px #ccc;
}

.active{
    background-color: var(--secondary-color);
}

.subfilters *{
    display: block;
    font-weight: 400;
}

.modal_content{
    display:flex;
    flex-wrap: nowrap;
    justify-content: space-around;
    width: 100%;
    height: auto;
}

.close{
    /* background-color: brown; */
    position:absolute;
    margin-left: 300px;
}

.product_container_img{
    box-shadow: 0 0 2px 1px #ccc;
}

.logo_product{
    position: absolute;
    opacity: 0.7;
    bottom: 150px;
    right: 10px;
}

.modal_container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.big_modal{
    width: 609px !important;
}