


/* FONT STYLE */
@import url('https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i');

/*------------------------------------------------------------------
    IMPORT FILES
-------------------------------------------------------------------*/

@import url(../../css/animate.css);
@import url(../../css/flaticon.css);
@import url(../../css/flaticon-1.css);
@import url(../../css/prettyPhoto.css);
@import url(../../css/owl.carousel.css);
@import url(../../css/font-awesome.min.css);


/*------------------------------------------------------------------
    SKELETON
-------------------------------------------------------------------*/

body {
    color: #999999;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    line-height: 1.80857;
	font-weight: 300;
	letter-spacing: 1.3px;

    position: relative;
    margin: 0;
    padding-bottom: 6rem;
    min-height: 100%;
}

html, body{
	/* height: 100%; */
}
html {
    height: 100%;
    box-sizing: border-box;
  }

body.demos .section-title img {
    max-width: 280px;
    display: block;
    margin: 10px auto;
}

.home_logo:hover{
    background-color: transparent !important;
}


body.demos .container-fluid {
    max-width: 1080px
}

a {
    color: #1f1f1f;
    text-decoration: none !important;
    outline: none !important;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: normal;
    position: relative;
    padding: 0 0 10px 0;
    font-weight: normal;
    line-height: 120% !important;
    color: #333333;
    margin: 0
}

h1 {
    font-size: 24px
}

h2 {
    font-size: 22px
}

h3 {
    font-size: 18px
}

h4 {
    font-size: 16px
}

h5 {
    font-size: 14px
}

h6 {
    font-size: 13px
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
    color: #212121;
    text-decoration: none!important;
    opacity: 1
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover {
    opacity: .8
}

a {
    color: #222222;
    text-decoration: none;
    outline: none;
}

a,
.btn {
    text-decoration: none !important;
    outline: none !important;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}

.btn-custom {
    margin-top: 20px;
    background-color: transparent !important;
    border: 2px solid #ddd;
    padding: 12px 40px;
    font-size: 16px;
}

.lead {
    font-size: 18px;
    line-height: 30px;
    color: #767676;
    margin: 0;
    padding: 0;
}

blockquote {
    margin: 20px 0 20px;
    padding: 30px;
}

ul, li, ol{
	margin: 0px;
	list-style: none;
	padding: 0px;
}


/*------------------------------------------------------------------
    WP CORE
-------------------------------------------------------------------*/

.first {
    clear: both
}

.last {
    margin-right: 0
}

.alignnone {
    margin: 5px 20px 20px 0;
}

.aligncenter,
div.aligncenter {
    display: block;
    margin: 5px auto 5px auto;
}

.alignright {
    float: right;
    margin: 10px 0 20px 20px;
}

.alignleft {
    float: left;
    margin: 10px 20px 20px 0;
}

a img.alignright {
    float: right;
    margin: 10px 0 20px 20px;
}

a img.alignnone {
    margin: 10px 20px 20px 0;
}

a img.alignleft {
    float: left;
    margin: 10px 20px 20px 0;
}

a img.aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto
}

.wp-caption {
    background: #fff;
    border: 1px solid #f0f0f0;
    max-width: 96%;
    /* Image does not overflow the content area */
    padding: 5px 3px 10px;
    text-align: center;
}

.wp-caption.alignnone {
    margin: 5px 20px 20px 0;
}

.wp-caption.alignleft {
    margin: 5px 20px 20px 0;
}

.wp-caption.alignright {
    margin: 5px 0 20px 20px;
}

.wp-caption img {
    border: 0 none;
    height: auto;
    margin: 0;
    max-width: 98.5%;
    padding: 0;
    width: auto;
}

.wp-caption p.wp-caption-text {
    font-size: 11px;
    line-height: 17px;
    margin: 0;
    padding: 0 4px 5px;
}



/*------------------------------------------------------------------
    HEADER
-------------------------------------------------------------------*/
.navbar-dark .navbar-nav .nav-link.active, .active:hover{
	font-weight: 600;
	color: #ffffff !important;
	background: #3a3a3a;
}
/* Menu navbar */
.nav-link{
    color: #000 !important;
}
.nav-link:hover{
    color: #ffffff !important;
}
/* #navbarResponsive *:hover{
    color: #ffffff;
} */

.navbar-dark{
	background: #ffffff;
}

.navbar-dark .navbar-nav .nav-link{
	color: #3a3a3a;
	letter-spacing: 1px;
	font-size: 16px;
	position: relative;
}
.navbar-dark .navbar-nav .nav-item{
	margin: 0px 5px;
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus{
	color: #ffffff;
	background: #3a3a3a;
}

.navbar-dark .navbar-nav .nav-link::after {
    content: '';
    width: 0;
    height: 5px;
    background: #ffffff;
    position: absolute;
    bottom: 0;
    left: 0;
	right: 0;
	text-align: center;
	margin: 0 auto;
    border-radius: 0px;
    -webkit-transition: all .5s;
    transition: all .5s;
    z-index: 0;
}
.navbar-dark .navbar-nav .nav-link.active::after {
	width: 50%;
}

.navbar-menu{
    justify-content: space-around !important;
}

.img-fluid{
    margin-left:10px;
    margin-top:10px;
    width: 250px;
}


/*------------------------------------------------------------------
    BUTTONS
-------------------------------------------------------------------*/






.btn-hover-new{
	color: #333333;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-o-transition: all 0.5s;
	transition: all 0.5s;
	position: relative;
	border: 1px solid #333333;
	overflow: hidden;
	padding: 0px 18px;
	display: inline-block;
}

.sim-btn{
	background: #ffffff;
	font-size: 18px;
	padding: 10px 20px;
	color: #3a3a3a;
	box-shadow: 0 7px 10px #e6e6e6;
	border: none;
}
.sim-btn:hover{
	color: #ffffff;
}

.btn-hover-new::after {
    content: attr(data-text);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    -webkit-transform: translate(-30%, 0);
    transform: translate(-30%, 0);
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.btn-hover-new span {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.btn-hover-new:hover{
	color: #ffffff;
	background-color: #333333;
}
.btn-hover-new:hover > span {
    opacity: 0;
    -webkit-transform: translate(0px,40px);
    transform: translate(0px,40px);
}
.btn-hover-new:hover::after {
    opacity: 1;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
}

/*------------------------------------------------------------------
    BUTTONS
-------------------------------------------------------------------*/

.navbar-default .btn-light {
    padding: 0 20px;
    margin-left: 15px;
}

.btn {
    border: 0 !important;
}

.btn-light {
    padding: 10px 40px;
    font-size: 18px;
    border: 2px solid #ffffff !important;
    color: #ffffff;
    background-color: transparent;
}

.btn-dark {
    padding: 13px 40px;
    font-size: 18px;
    border: 1px solid #ececec !important;
    color: #1f1f1f;
    background-color: transparent;
}

.btn-light:hover,
.btn-light:focus {
    border-color: rgba(255, 255, 255, 0.6);
    color: rgba(255, 255, 255, 0.6);
}




#mainNav{
    background-color: var(--primary-color);
}


@keyframes rotate {
    0% { border-width: 10px; }
    25% { border-width: 3px; }
    50% { 
      transform: rotate(115deg); 
      border-width: 10px;
    }
    75% { border-width: 3px;}
    100% { border-width: 10px;}
  }

  .right{
      /* justify-content: flex-end; */
      margin-left: auto !important;
  }