	/*
  	Flaticon icon font: Flaticon
  	Creation date: 10/07/2018 13:22
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("../fonts/Flaticon_new.eot");
  src: url("../fonts/Flaticon_new.eot?#iefix") format("embedded-opentype"),
       url("../fonts/Flaticon_new.woff") format("woff"),
       url("../fonts/Flaticon_new.ttf") format("truetype"),
       url("../fonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../fonts/Flaticon_new.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
	font-family: Flaticon;
	font-style: normal;
}

.flaticon-projector-screen:before { content: "\f100"; }
.flaticon-idea:before { content: "\f101"; }
.flaticon-review:before { content: "\f102"; }
.flaticon-alarm-clock:before { content: "\f103"; }