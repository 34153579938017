	/*
  	Flaticon icon font: Flaticon
  	Creation date: 04/07/2018 13:02
  	*/

@font-face {
  font-family: "Flaticon";
  /* src: url("../fonts/Flaticon.eot"); */
  src: url("../fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("../fonts/Flaticon.woff") format("woff"),
       url("../fonts/Flaticon.ttf") format("truetype");
       /* url("..fonts/Flaticon.svg#Flaticon") format("svg"); */
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../fonts/Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
	font-family: Flaticon;
	font-style: normal;
	margin-left: 0px;
}

.flaticon-idea-1:before { content: "\f100"; }
.flaticon-idea:before { content: "\f101"; }
.flaticon-discuss-issue:before { content: "\f102"; }
.flaticon-process:before { content: "\f103"; }
.flaticon-development:before { content: "\f104"; }
.flaticon-seo:before { content: "\f105"; }