.footer{
    position: absolute;
    width: 100%;
    /* right: 0; */
    bottom: 0;
    /* left: 0; */
    /* margin-top: 100px; */
}
.footer {
    font-family: 'Poppins', sans-serif;
    border-top: 1px dashed rgba(255, 255, 255, 0.1);
    /* background-color: #f8f7ce; */
    background-color: var(--secondary-color);
    box-sizing: border-box;
    width: 100%;
    text-align: left;
    padding: 30px 0px;
    overflow: hidden;
    /* margin-top:10px ; */
}

/* Footer left */

.footer-distributed{
	text-align: center;
}

.footer-distributed .footer-links {
    margin: 0 0 10px;
    text-transform: uppercase;
    padding: 0;
}

.footer-distributed .footer-links a {
    display: inline-block;
    line-height: 1.8;
    margin: 0 10px 0 10px;
    text-decoration: none;
}
.footer-distributed .footer-links a:hover{
	color: #432382;
}

.footer-distributed .footer-company-name {
    font-weight: 300;
    margin: 0 10px;
    color: #3a3a3a;
    padding: 0;
}


/* Footer right */

.footer-distributed .footer-right {
    float: right;
}


/* The search form */

.footer-distributed form {
    position: relative;
}

.footer-distributed form input {
    display: block;
    border-radius: 3px;
    box-sizing: border-box;
    background-color: #181818;
    border: none;
    font: inherit;
    font-size: 15px;
    font-weight: normal;
    color: #999;
    width: 400px;
    padding: 18px 50px 18px 18px;
}

.footer-distributed form input:focus {
    outline: none;
}


/* Changing the placeholder color */

.footer-distributed form input::-webkit-input-placeholder {
    color: #999;
}

.footer-distributed form input::-moz-placeholder {
    opacity: 1;
    color: #999;
}

.footer-distributed form input:-ms-input-placeholder {
    color: #999;
}


/* The magnify glass icon */

.footer-distributed form i {
    width: 18px;
    height: 18px;
    position: absolute;
    top: 16px;
    right: 18px;
    color: #999;
    font-size: 18px;
    margin-top: 6px;
}

.footer-distributed .footer-company-name a{
	color: #432382;
}

.footer-distributed .footer-company-name a:hover{
	color: #000000;
}

.footer_social_container .social{
    width: 35px;
    height: auto;
    /* margin:0; */
    /* margin-left: 50px; */
    /* margin-top: 0px; */
    border-style: solid;
    border-color: #000000;
    border-width: 1px;
}
.social_footer, .social_media{
    max-width: 45px;
    margin-right: 30px;
}
.social_footer:hover{
    cursor: pointer;
}
.footer-links:hover *{
    background-color: none !important;
}
.footer_link:hover{
    background-color: var(--secondary-color) !important;
    color: #007bff !important;
}