.about_container{
    margin-top: 140px;
    margin-left:0px;
    margin-right: 0px;
    height: 100%;
    /* margin-bottom: 80px; */
    /* background-color: var(--primary-color); */
    background-color:#f1f1f1;
    width: 100%;
    display: flex;
    flex-direction: row;
}

.image{
    /* display: flex; */
    /* max-width: 550px; */
    /* min-width: 300px; */
    /* width: 675px; */
    width: 100%;

    /* max-height:600px; */
    /* min-height: 300px; */
    /* height: 506px; */
    height: auto;
    box-shadow: 0 0 30px #ccc;
    border-style: solid;
    border-width: 1px;
    margin-left: 20px;
    margin-right: 20px;
}

.section-title{
    width: 50%;
    margin-left: 20px;
}

.section-title, .about_container__image{
    margin-top: 50px;
}

.section-title p{
    letter-spacing: 2px;
    font-size: larger;
}

.about_container__image{
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}
.social{
    width: 35px;
    height: auto;
    margin-left: 50px;
    margin-top: 0px;
}

.image_maps:hover{
    -webkit-box-shadow: 0px 0px 15px -4px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 15px -4px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 15px -4px rgba(0,0,0,0.75);
}

.image_maps{
    border-style: solid;
    border-width: 1px;
    border-color: black;
    margin-bottom: 100px;
}

.social_container{margin-bottom: 50px;}
