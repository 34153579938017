.catalogos_container{
    /* width: 100%; */
    margin-top: 150px;
    margin-bottom: 150px;
}

.catalogs_links{
    /* margin-left: 50px; */
    width: 100%;
}
.catalogs_links{
    align-self:center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    max-width: 70%;
    margin-left: auto;
    margin-right: auto;
    /* align-items: center; */
}
.catalogo_item{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
}
.catalogo_item:hover{
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 10px 1px rgba(130,130,130,1);
    -moz-box-shadow: 0px 0px 10px 1px rgba(130,130,130,1);
    box-shadow: 0px 0px 10px 1px rgba(130,130,130,1);
}